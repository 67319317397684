import { createApi } from ".";
import { STT_DUPLICATED, STT_OK } from "src/const/app.const";
import toast from 'react-hot-toast';
import { Location, Transaction } from "src/types/models";
import { env } from "src/env/client.mjs";
const axios = require("axios");

interface IJoinCommunity {
  category: string;
  address: string;
  email: string;
}

type GoogleLatLng = { lat: number; lng: number };

export interface GoogleAddressInfo {
  formatted_address: string;
  place_id: string;
  types: string;
  geometry: {
    bounds: {
      northeast: GoogleLatLng;
      southwest: GoogleLatLng;
    };
    viewport: {
      northeast: GoogleLatLng;
      southwest: GoogleLatLng;
    };
    location: GoogleLatLng;
    location_type: string;
  };
  address_components: Array<{
    long_name: string;
    short_name: string;
    types: string[];
  }>;
}

export const join = async (businessId: string, data: IJoinCommunity) => {
  const res = await createApi().post<any>("/location/community", {
    business_id: businessId,
    ...data,
  });
  if (res.status === 400) {
    toast.error("Please enter a more specific address.");
    return false;
  }
  if (res.status === 403) {
    toast.error("Sorry, this location already exists.");
    return false;
  }

  if (res.status !== 200) {
    toast.error(`Error ${res.data.message}`);
    return false;
  }
  return true;
};
export const followLocations = async (locations: string[]) => {
  const res = await createApi().post<{ ok: true; message?: string }>("/location/follow", {
    locations,
  });

  if (res.status !== 200) {
    return toast.error(res.data?.message || "Sorry, something went wrong.");
  }
  return res;
};

export const unfollowLocations = async (locations: string[]) => {
  const res = await createApi().post<{ ok: true; message?: string }>("/location/unfollow", {
    locations,
  });

  if (res.status !== 200) {
    return toast.error(res.data?.message || "Sorry, something went wrong.");
  }
  return res;
};
//followerinfo 
export const followersInfo = async (businessId: string, offset: number, limit: number) => {
  try {
    const api = createApi();

    const response = await api.get<{
      results: any[]; // Adjust the type based on your actual API response structure
      message?: string;
    }>(`/location/getFollowerInfo?businessId=${businessId}&offset=${offset}&limit=${limit}`);

    return response.data.results;
  } catch (error) {
    console.error("Error fetching follower info:", error);
    throw error;
  }
};

// TODO
export const getLocations = async (data: any) => {
  const res = await createApi().get<{
    results: Location[];
    message?: string;
  }>("/location/community/getBusinessByLocationId", data);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return { results: [] };
  }
  return { results: res.data.results };
};

export const getLocationById = async (data: { id: string }) => {
  const res = await createApi().get<{ results: Location[]; message: string }>("/location", data);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return res.data.results[0] as Location;
};

export const getTransactions = async (data: {
  id: string;
  type: "user" | "business";
  limit?: number;
  offset?: number;
}) => {
  const res = await createApi().get<{
    results: Transaction[];
    total: number;
    message?: string;
  }>("/wallet/transactions", data);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return { results: [], total: 0 };
  }
  return { results: res.data.results, total: res.data.total };
};

export const getLocationDetails = async (data: { id: string }) => {
  const res = await createApi().get<{
    result: Location;
    message: string;
  }>("/location/info", data);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return res.data.result;
};

export const updateLocation = async (data: {
  id: string;
  category: string;
  email: string;
}) => {
  const res = await createApi().put<any>("/location", data);
  if (res.status !== STT_OK || !res.data) {
    return toast.error(`Error: ${res.data?.message || res.originalError}`);
  }
  return res;
};

export const deleteLocation = async (id: string) => {
  const res = await createApi().delete<{ message: string; ok: true }>(`/location?id=${id}`);

  if (res.status === STT_DUPLICATED) {
    toast.error(`Sorry, you cannnot delete locations with ongoing opportunities.`);
    return false;
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }
  return res;
};

export const getZipcodeFromGeoCoords = async (lat: number, lng: number) => {
  const API_KEY = env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

  if (!API_KEY) {
    toast.error("Sorry, something went wrong. Please try again later.");
    return;
  }

  const API_URI = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;
  const res = await axios.get(API_URI);

  if (res.status !== STT_OK || !res.data) {
    // toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }

  return res.data as { results: GoogleAddressInfo[] };
};

export const getGeoCoordsFromZipcode = async (zipcode: string) => {
  const API_KEY = env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

  if (!API_KEY) {
    return;
  }

  const API_URI = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${API_KEY}`;
  const res = await axios.get(API_URI);

  if (res.status !== STT_OK || !res.data) {
    // toast.error(`Error: ${res.data?.message || res.originalError}`);
    return false;
  }

  return res.data;
};

export const fetchMultipleLocation = async (locations: string[]) => {
  return await Promise.all(
    locations.map(async (id: string) => {
      const locationDetail = await getLocationById({ id: id });

      if (!locationDetail) {
        return null;
      }

      return {
        id: locationDetail.id,
        address: locationDetail.address,
        businessHours: locationDetail.businessHours,
      };
    })
  );
}
