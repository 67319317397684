import React, { useEffect, useState } from "react";
import FilterPopup from "./FilterPopup";
import useClickOutside from "src/hooks/useClickOutside";
import { categoryMap, useFilterContext } from "../../contexts/FilterContext/FilterContext";

const DealTypeFilter = () => {
  const [dealsChecked, setDealsChecked] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [category, setCategory] = useState("all");
  const [eventsChecked, setEventsChecked] = useState(true);
  const { filterState, filterDispatch } = useFilterContext();

  const handleToggleFilter = () => setIsFilterOpen((prev) => !prev);

  const handleCloseFilter = () => setIsFilterOpen(false);

  const handleDealsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dealsChecked && !eventsChecked) {
      setDealsChecked(false);
      setEventsChecked(true);
      setCategory("all");
    } else {
      if (!event.target.checked) setCategory("all");
      setDealsChecked(event.target.checked);
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIsFilterOpen(false);
    if (event.target.value !== "all") {
      if (!dealsChecked) {
        setDealsChecked(true);
      }
      setEventsChecked(false);
    }
    setCategory(event.target.value);
  };

  const handleEventsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!dealsChecked && eventsChecked) {
      setDealsChecked(true);
      setEventsChecked(false);
    } else {
      if (event.target.checked) setCategory("all");
      setEventsChecked(event.target.checked);
    }
  };

  const getButtonTitle = (): string => {
    if (filterState.type.length === 2) {
      if (filterState.category === "all") {
        return "Anything";
      } else {
        return `${categoryMap[filterState.category]?.replace("&#39;", "'")} and Events`;
      }
    } else if (filterState.type.length === 1) {
      if (filterState.type[0] === "deal") {
        if (filterState.category === "all") {
          return "Deals";
        } else {
          return categoryMap[filterState.category]?.replace("&#39;", "'") as string;
        }
      } else {
        return "Events";
      }
    } else {
      return "Nothing";
    }
  };

  const buttonTitle = getButtonTitle();

  useEffect(() => {
    const typePayload = [];
    if (dealsChecked) typePayload.push("deal");
    if (eventsChecked) typePayload.push("event");
    if (!dealsChecked && !eventsChecked) typePayload.push("deal", "event");
    filterDispatch({ type: "SET_TYPE", payload: typePayload });

    if (dealsChecked) {
      filterDispatch({ type: "SET_CATEGORY", payload: category });
    }
  }, [dealsChecked, eventsChecked, category]);

  const { ref, exceptionRef } = useClickOutside(handleCloseFilter);
  return (
    <div className="h-11 max-w-[160px] text-sm sm:text-base flex-1 md:relative">
      <div
        className="flex h-full cursor-pointer items-center justify-center rounded-3xl bg-white p-2 shadow"
        onClick={handleToggleFilter}
        ref={exceptionRef}
      >
        <span className="text-center align-middle line-clamp-2">{buttonTitle}</span>
      </div>
      {isFilterOpen && (
        <div ref={ref}>
          <FilterPopup align="left" width="w-[calc(100vw-50px)] ml-6 md:ml-0 md:w-96">
            <div className="flex flex-col gap-y-2 p-4">
              <div className="flex items-center gap-x-2">
                <input
                  type="checkbox"
                  checked={dealsChecked}
                  onChange={handleDealsChange}
                  className="h-6 w-6 appearance-none rounded-full border-yellow-500 checked:appearance-none checked:border-transparent checked:bg-yellow-500 checked:hover:bg-yellow-500 focus:ring-0 focus:ring-transparent checked:focus:bg-yellow-500"
                />
                <label>Deals</label>
              </div>
              <div className="pb-6">
                <select
                  value={category}
                  onChange={handleCategoryChange}
                  className="h-12 w-full rounded-lg border-none bg-neutral-50 px-2 shadow focus:border-transparent focus:outline-none focus:ring-0"
                >
                  <option value="all">All Business Categories</option>
                  <option value="art-studio">Art Studio</option>
                  <option value="auto-shop">Auto Shop</option>
                  <option value="bakery">Bakery</option>
                  <option value="bar">Bar</option>
                  <option value="beauty-shop">Beauty Shop</option>
                  <option value="boat-tour-agency">Boat Tour Agency</option>
                  <option value="book-store">Book Store</option>
                  <option value="brewery">Brewery</option>
                  <option value="chiropractor">Chiropractor</option>
                  <option value="clothing-store">Clothing Store</option>
                  <option value="coffee-shop">Coffee Shop</option>
                  <option value="coworking-space">Coworking Space</option>
                  <option value="dance-studio">Dance Studio</option>
                  <option value="dentist">Dentist</option>
                  <option value="distillery">Distillery</option>
                  <option value="entertainment">Entertainment</option>
                  <option value="entrepeneur">Entrepreneur</option>
                  <option value="farm">Farm</option>
                  <option value="farmers-market">Farmers Market</option>
                  <option value="florist">Florist</option>
                  <option value="food-drink-shop">Food & Drink Shop</option>
                  <option value="general-store">General Store</option>
                  <option value="gym">Gym</option>
                  <option value="hair-salon">Hair Salon</option>
                  <option value="home-services">Home Services</option>
                  <option value="ice-cream-shop">Ice Cream Shop</option>
                  <option value="personal-trainer">Personal Trainer</option>
                  <option value="pet-shop">Pet Shop</option>
                  <option value="restaurant">Restaurant</option>
                  <option value="theater">Theater</option>
                  <option value="wellness-center">Wellness Center</option>
                  <option value="winery">Winery</option>
                </select>
              </div>
              <div className="flex items-center gap-x-2">
                <input
                  type="checkbox"
                  checked={eventsChecked}
                  onChange={handleEventsChange}
                  className="h-6 w-6 appearance-none rounded-full border-yellow-500 checked:appearance-none checked:border-transparent checked:bg-yellow-500 checked:hover:bg-yellow-500 focus:ring-0 focus:ring-transparent checked:focus:bg-yellow-500"
                />
                <label>Events</label>
              </div>
            </div>
          </FilterPopup>
        </div>
      )}
    </div>
  );
};

export default DealTypeFilter;
